.module {
  position: relative;
  border: 1px solid white;
  padding: 10px;
  width: 400px;
  margin-bottom: 10px;
}
.light {
  position: absolute;
  top: 10px;
  right: 10px;
  background: gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.module.disarmed .light {
  background: lime;
}
.module.disarmed {
  border: 1px solid lime;
}
