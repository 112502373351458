.button {
  padding: 10px;
  border: none;
  width: 100px;
  height: 100px;
}

.strip {
  display: inline-block;
  width: 25px;
  height: 100px;
}

.container {
  display: flex;
}
