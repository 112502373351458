.wrapper {
  display: inline-block;
}
.key {
  position: relative;
  display: inherit;
  padding: 3px;
}
.image {
  max-width: 147px;
  margin-bottom: -4px;
  padding-top: 18px;

  background-color: #DDCEB9;
}
.key:active .image {
  box-shadow: inset 0px 0px 20px -5px black;
}
.strip {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 10px;
  width: 35px;
  height: 10px;

  margin: auto;

  background-color: #000000;
}
.strip.disarmed {
  background-color: #00ff00;
}
