.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-right: 30px;
}
.button {
  width: 100%;
  flex: 1;
  min-height: 50px;
}
