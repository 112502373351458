* {
  font-family: sans-serif;
  box-sizing: border-box;
}
body {
  margin: 10px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 0px;
  background-color: #222326;
  color: #FBF8F8;
  user-select: none;
}
